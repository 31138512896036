import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Spin, Alert, message, Table } from "antd";
import { observer } from "mobx-react-lite";
import { Store } from "../../../stores/stores";
import { Buttons } from "../../../components";
import "./style.css";
import moment from "moment";

export const OrderRetry: React.FC = observer(() => {
    const { ordersStore } = Store;
    const { orderId } = useParams<{ orderId: string }>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    
    useEffect(() => {
        const fetchOrder = async () => {
            if (orderId) {
                setLoading(true);
                try {
                    console.log("Fetching order for ID:", orderId);
                    await ordersStore.getOrder(orderId);
                    setError(null);
                } catch (err) {
                    console.error("Failed to load order:", err);
                    setError("Failed to load order details. Please try again.");
                } finally {
                    setLoading(false);
                }
            } else {
                console.error("Order ID is missing in the route.");
                setError("Order ID is missing in the URL.");
                setLoading(false);
            }
        };

        fetchOrder();
    }, [orderId, ordersStore]);

    const handleRetry = async () => {
        if (orderId) {
            try {
                await ordersStore.retryOrderProcessing(orderId);
                message.success("Order retry initiated successfully.");
            } catch (err) {
                console.error("Error retrying order processing:", err);
                message.error("Failed to retry order processing.");
            }
        } else {
            message.error("Order ID is missing. Cannot retry processing.");
        }
    };

    const order = ordersStore.currentOrder;

    if (!order) {
        return (
            <div className="order-retry-container">
                <Alert type="warning" message="No order details available." />
            </div>
        );
    }

        const columns = [
            {
                title: "SKU",
                dataIndex: "sku",
                key: "sku",
                width: "40%",
            },
            {
                title: "Price",
                dataIndex: "price",
                key: "price",
                render: (text: number) => `R ${text.toFixed(2)}`,
                width: "30%",
            },
            {
                title: "Quantity",
                dataIndex: "orderQty",
                key: "orderQty",
                width: "30%",
            },
        ];

    return (
        <main id="home" className='home-content-page'>
            <div className="order-retry-page">
            <h1 className="order-retry-title">Order Details</h1>
            <div className="order-retry-details">
                <div className="order-retry-item">
                    <strong>Order Reference Number:</strong> {order.refNo}
                </div>
                <div className="order-retry-item">
                    <strong>Client ID:</strong> {order.clientId}
                </div>
                <div className="order-retry-item">
                    <strong>Client Name:</strong> {order.client.userAuth.firstName || "N/A"} {order.client.userAuth.lastName || "N/A"}
                </div>
                <div className="order-retry-item">
                    <strong>Client Email:</strong> {order.client.userAuth.emailAddress || "N/A"}
                </div>
                <div className="order-retry-item">
                    <strong>Total Price:</strong>{" "}
                    {order.totalPrice ? `R ${order.totalPrice.toFixed(2)}` : "N/A"}
                </div>
                <div className="order-retry-item">
                    <strong>VAT:</strong> {order.vat ? `R ${order.vat.toFixed(2)}` : "N/A"}
                </div>
                <div className="order-retry-item">
                    <strong>Grand Total:</strong>{" "}
                    {order.grandTotal ? `R ${order.grandTotal.toFixed(2)}` : "N/A"}
                </div>
                <div className="order-retry-item">
                    <strong>Comments:</strong> {order.comments || "N/A"}
                </div>
                <div className="order-retry-item">
                    <strong>Created At:</strong> {order.createdAt ? moment(order.createdAt).format("DD-MM-YYYY") : "N/A"}
                </div>
                <div className="order-retry-item">
                    <strong>Date Ordered:</strong> {order.dateOrdered ? moment(order.dateOrdered).format("DD-MM-YYYY") : "N/A"}
                </div>
                <div className="order-retry-items">
                    <h3>Ordered Items</h3>
                    <Table
                        dataSource={order.orderInfo}
                        columns={columns}
                        pagination={false}
                        rowKey="sku"
                        scroll={{ y: 200 }}
                        className="order-info-table"
                    />
                </div>
            </div>
            <div className="order-retry-button">
                <Buttons.Small
                    type="link"
                    text="Retry Processing Order"
                    onClick={handleRetry}
                />
            </div>
            </div>
        </main>
    );
});
