import { Button, Dropdown, Input, Table, Modal, Select } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { Spinners } from "..";
import { Store } from "../../stores/stores";
import { ISurveyResponse, ISurveyResponseUpdate } from "../../utils/interfaces";
import { QuestionType, VerdictStatus } from "../../utils/enums";

const { Option } = Select;

interface SurveyResponsesTableProps {
    surveyId: string;
}
const verdictMap = {
    [VerdictStatus.Pending]: 'Pending',
    [VerdictStatus.Accepted]: 'Accepted',
    [VerdictStatus.Rejected]: 'Rejected',
};

const questionTypeMap = {
    [QuestionType.Email]: 0,
    [QuestionType.CV]: 1,
    [QuestionType.Name]: 2,
};

const SurveyResponsesTable: React.FC<SurveyResponsesTableProps> = observer(({ surveyId }) => {
    const { surveyResponseStore, surveyStore, surveyQuestion } = Store;
    const [filteredResponses, setFilteredResponses] = useState<ISurveyResponse[]>([]);
    const [selectedResponses, setSelectedResponses] = useState<string[]>([]);
    const [bulkVerdict, setBulkVerdict] = useState<VerdictStatus | null>(null);
    const [searchQuery, setSearchQuery] = useState('');

    const currentSurvey = surveyStore.hrSurveys.value.data.find(survey => survey.id === surveyId);
    const surveyName = currentSurvey ? currentSurvey.name : 'Unknown Survey';

    useEffect(() => {
        if (!surveyQuestion.surveyQuestions.value.data.length) {
            surveyQuestion.loadSurveyQuestions();
        }
    }, []);

    const allQuestions = surveyQuestion.surveyQuestions.value.data.filter(q => q.surveyId === surveyId);
    const sortedQuestions = [...allQuestions].sort((a, b) => a.position - b.position);

    const getResponseValue = (response: ISurveyResponse, questionId: string) => {
        const answer = response.response.find(r => r.questionId === questionId);
        return answer ? answer.value.join(', ') : 'N/A';
    };

    const columns = [
        ...sortedQuestions.map((question) => {
            const isOptionQuestion = question.options && question.options.length > 0;
            return {
                title: question.text,
                key: question.id,
                filters: isOptionQuestion
                    ? question.options!.map((option: string) => ({
                          text: option,
                          value: option,
                      }))
                    : undefined,
                onFilter: isOptionQuestion
                    ? (value: any, record: ISurveyResponse) => {
                          const responseValue = getResponseValue(record, question.id);
                          return responseValue.includes(value);
                      }
                    : undefined,
                render: (text: any, record: ISurveyResponse) =>
                    getResponseValue(record, question.id),
            };
        }),
        {
            title: "Verdict",
            key: "verdict",
            filters: [
                {
                    text: 'Pending',
                    value: "Pending",
                },
                {
                    text: 'Accepted',
                    value: "Accepted",
                },
                {
                    text: 'Rejected', 
                    value:"Rejected",
                },
            ],
            onFilter: (value: any, record: ISurveyResponse) =>  verdictMap[record.verdict] === value as string,
            render: (text: any, record: ISurveyResponse) => (
                <div style={{ color: getColor(verdictMap[record.verdict]) }}>
                    {verdictMap[record.verdict]}
                </div>
            ),
        },
        {
            title: "View",
            key: "view",
            render: (_:any, record:ISurveyResponse) => (
                <Button onClick={() => handleViewResponse(record.id)}>
                    View
                </Button>
            ),
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: any[]) => {
            setSelectedResponses(selectedRowKeys.map((key) => key.toString()));
        },
    };

    const getColor = (verdict: string) => {
        switch (verdict) {
            case verdictMap[VerdictStatus.Pending]:
                return 'orange';
            case verdictMap[VerdictStatus.Accepted]:
                return 'green';
            case verdictMap[VerdictStatus.Rejected]:
                return 'red';
            default:
                return 'black';
        }
    };
    
    const findAnswerByType = (response: ISurveyResponse, questionType: any) => {
        const answer = response.response.find(a => a.type === questionType);
        return answer ? answer.value[0] : "No answer provided";
    };

    const handleBulkVerdictChange = () => {
        if (!bulkVerdict) {
            Modal.warning({
                title: "No Verdict Selected",
                content: "Please select a verdict before applying the change.",
            });
            return;
        }
    
        Modal.confirm({
            title: "Confirm Bulk Verdict Change",
            content: `Are you sure you want to change the verdict for the selected responses to "${verdictMap[bulkVerdict]}"?`,
            onOk: async () => {
                const updates: ISurveyResponseUpdate[] = selectedResponses
                .map(id => {
                    const response = filteredResponses.find(r => r.id === id);
                    if (!response) return null;
            
                    const emailAnswer = findAnswerByType(response, questionTypeMap[QuestionType.Email]);
                    const nameAnswer = findAnswerByType(response, questionTypeMap[QuestionType.Name]);

                    return {
                        id,
                        verdict: bulkVerdict as VerdictStatus,
                        recipientEmail: emailAnswer,
                        recipientName: nameAnswer,
                    };
                })
                .filter((update): update is ISurveyResponseUpdate => update !== null);
                if (updates.length > 0) {
                    try {
                        await surveyResponseStore.updateBatchSurveyResponses(updates);
                        await surveyResponseStore.loadSurveyResponses(); 
                        setSelectedResponses([]); 
                    } catch (e) {
                        console.error('Error updating survey responses', e);
                        Modal.error({
                            title: "Error",
                            content: "There was an error updating the survey responses.",
                        });
                    }
                } else {
                    Modal.warning({
                        title: "No Valid Responses",
                        content: "No valid survey responses found to update.",
                    });
                }
            },
        });
    };

    const handleViewResponse = (id: string) => {
        surveyResponseStore.loadSurveyResponse(id);
        surveyResponseStore.setShowResponseDrawer(true);
    };

    useEffect(() => {
        if (surveyResponseStore.surveyResponses.value.data.length === 0) {
            surveyResponseStore.loadSurveyResponses();
        }
    }, []);

    useEffect(() => {
        const filtered = surveyResponseStore.surveyResponses.value.data.filter(
            response => response.surveyId === surveyId
        );
        setFilteredResponses(filtered);
    }, [surveyId, surveyResponseStore.surveyResponses.value.data]);

    return (
        <Spinners.CustomSpinner
            title="Getting Survey Responses"
            isSpinning={surveyResponseStore.surveyResponses.isLoading}
        >
            <div className='pageheader-container'>
                <h1 className='header'>Responses for Survey: {surveyName}</h1>
                <div className='pageheader-options'>
                    <div className="pageheader-seperator">
                        <Dropdown
                            overlay={[] as any}
                            className='pageheader-options-categories'
                            disabled={true}
                        >
                            <Button className='dropdown-btn'>
                                Filters
                            </Button>
                        </Dropdown>
                    </div>

                    <Input
                        size='large'
                        style={{ margin: 0 }}
                        className='pageheader-options-search contract-pricing-search'
                        placeholder="Search list..."
                        prefix={<RiSearchLine color='#CBD5E0' />}
                        onChange={(e) => {
                           setSearchQuery(e.target.value);
                        }}
                    />
                </div>

                <Select
                    placeholder="Select Bulk Verdict"
                    style={{ width: 200, marginRight: 16 }}
                    onChange={(value: VerdictStatus) => setBulkVerdict(value)}
                >
                    <Option value={VerdictStatus.Accepted}>Accepted</Option>
                    <Option value={VerdictStatus.Rejected}>Rejected</Option>
                </Select>

                <Button
                    onClick={handleBulkVerdictChange}
                    disabled={selectedResponses.length === 0}
                >
                    Apply Bulk Verdicts
                </Button>
            </div>

            <Table
                rowKey='id'
                className='custom-table contractpricing-table'
                columns={columns}
                dataSource={filteredResponses.filter((response) => {
                    if (!searchQuery) return true;
            
                    const searchUpper = searchQuery.toUpperCase();
            
                    return response.response.some((r) =>
                        r.value.some((v: string) => v.toUpperCase().includes(searchUpper))
                    );
                })}
                rowSelection={rowSelection}
                pagination={false}
            />
        </Spinners.CustomSpinner>
    );
});

export default SurveyResponsesTable;