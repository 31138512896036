import { CopyOutlined, PlusOutlined } from "@ant-design/icons";
import { Col, Form, FormInstance, Input, message, Row, Select, Switch } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState, useRef } from "react";
import { Buttons, Drawers, Files, Search } from "..";
import { formDataValues } from "../../services/formData";
import { Store } from "../../stores/stores";
import { ISkuMaster } from "../../utils/interfaces";
import { DebounceSelectProp } from "../Search/DebounceSearch";
import DuplicateSkuItem from "./DuplicateSkuItem";
import "./style.css";
import { copyObjects } from "../../services";
import { PopupConfirm } from "../Modals";
import { $generateHtmlFromNodes } from "@lexical/html";
import { LexicalEditor } from "lexical";
import HtmlEditor from "./EditorWithGenerator";

type NewSkuItemProp = {
    item?: ISkuMaster,
    isLoading?: boolean
    onFormFinish: (sku: FormData) => void,
};

export const CustomForm = ({ item, component: Component, index, ...rest }: any) => {
    return (
        <Form.Item
            key={index}
            label={item.label}
            name={item.name}
            rules={[{ required: item?.required, }]}
            valuePropName={item?.valuePropName}
            {...rest}
        >
            <Component />
        </Form.Item>
    );
}
const theme = {};

const onError = (error: any) => {
  console.error(error);
};





const NewSkuItem = observer(({ item, isLoading, onFormFinish }: NewSkuItemProp) => {
    const [fileList, setFileList] = React.useState<UploadFile[]>([]);
    const [safetyFiles, setSafetyFiles] = React.useState<UploadFile[]>([]);
    const [technicalFiles, setTechnicalFiles] = React.useState<UploadFile[]>([]);
    const [removedFiles, setRemovedFiles] = React.useState<string[]>([]);
    const [selectedFile, setSelectedFile] = React.useState(0);
    const [showDuplicatePanel, setShowDuplicatePanel] = useState(false);
    const [showDuplicateSearchTermsPanel, setShowDuplicateSearchTermsPanel] = useState(false);
    const { inventoryStore } = Store;
    const formRef = React.createRef<FormInstance>();
    const [editorStateJSON, setEditorStateJSON] = useState(item?.editorContent || '');
    const [editorStateHTML, setEditorStateHTML] = useState('');
    const editorRef = useRef<{ updateEditorWithHtml: () => void }>(null);

    const initialValues = Object.assign({}, item, {
        categoryName: item?.category?.name,
        attributes: item?.attributes ? [...item.attributes] : [],
        complementaryProducts: item?.complementaryProducts ? [...item.complementaryProducts] : [],
    });

    const validateMessages = {
        required: "Field is required!",
    };

    const selectCategory = ({ ...props }: any) => (
        <Search.DebounceSearch<DebounceSelectProp>
            {...props}
            fetchOptions={(_) => inventoryStore.searchCategory(_)}
        />
    );

    const selectAttributes = ({ ...props }: any) => (
        <Search.DebounceSearch<DebounceSelectProp>
            fetchOptions={(_) => inventoryStore.searchAtrribute(_)}
            mode="multiple"
            {...props}
        />
    );

    const selectComplementaryItems = ({ ...props }: any) => (
        <Search.DebounceSearch<DebounceSelectProp>
            fetchOptions={(_) => inventoryStore.searchComplementaryItems(_)}
            mode="multiple"
            {...props}
        />
    );
    const SearchTermsInput = ({ ...props }: any) => (
        <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Add search terms"
            {...props}
        />
    );

    const handleDuplicateSearchTerms = async (skuItem: ISkuMaster) => {
        formRef.current?.setFieldsValue({
            searchTerms: skuItem.searchTerms ?? [],
        });
        setShowDuplicateSearchTermsPanel(false);
    };


    const rowFormData = [
        [
            { name: 'categoryName', label: "Category", component: selectCategory, required: true },
            { name: 'sku', label: "SKU", component: Input, required: true },
        ],
        [
            { name: 'productName', label: "Product Name", component: Input, required: true },
        ],
    ];

    const otherFormData = [
        [{ name: 'fullDescription', label: "Full Description", component: Input, required: true },],
        [{ name: 'attributes', label: "Attributes", component: selectAttributes, required: false },],
        [{ name: 'complementaryProducts', label: "Complementary Products", component: selectComplementaryItems, required: false },],
    ];

    const radioFormData = [ [
        { name: 'new', label: "New", component: Switch, valuePropName: "checked", required: false },
        { name: 'featured', label: "Featured", component: Switch, valuePropName: "checked", required: false },
        { name: 'active', label: "Active", component: Switch, valuePropName: "checked", required: false },
    ],]

    const onFinish = async (values: any) => {
        let formData = new FormData();
        let formValues = Object.assign({}, item, values, {
            categoryId: values.categoryName === initialValues?.categoryName ? item?.categoryId : values?.categoryName,
        });
    
        if (item) {
            formData.append('id', item.id)
        }
    
        await new Promise<void>(resolve => {
          setEditorStateJSON(jsonString => {
            formData.append('editorContent', jsonString);
            return jsonString;
          });
          setEditorStateHTML(htmlString => {
            formData.append('editorContentHtml', htmlString);
            return htmlString;
          });
          resolve();
        });
    
        
        if (fileList.length > 0) {
            const mainImage = fileList[0];
            
            if (mainImage.originFileObj) {
                formData.append('mainImageUpload', mainImage.originFileObj);
            } else if (mainImage.url) {
                formData.append('subCategoryLongImage', mainImage.url);
            }
            fileList.slice(1).forEach(file => {
                if (file.originFileObj) {
                    formData.append('productImagesUpload', file.originFileObj);
                }
            });
    
            formData = formDataValues(formData, formValues)
            safetyFiles.forEach(file => file?.originFileObj && formData.append('safetyDataSheetsUpload', file.originFileObj));
            technicalFiles.forEach(file => file?.originFileObj && formData.append('technicalFilesUpload', file.originFileObj));
            removedFiles.forEach(url => formData.append('filesToDelete', url));
            onFormFinish(formData);
        } else {
            message.error('Add main image.')
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        if (!item) {
            message.error('Could not add inventory.')
        } else {
            message.error('Could not update inventory.')
        }
    };

    const handleDuplicateFromItem = async (skuItem: ISkuMaster) => {
        const currentSkuValue = formRef.current?.getFieldValue("sku"); 
        formRef.current?.setFieldsValue(copyObjects(skuItem, {
            id: "",
            sku: currentSkuValue,
            categoryName: skuItem.categoryId,
            attributes: skuItem.attributes ?? [],
            complementaryProducts: skuItem.complementaryProducts ?? [],
            subCategoryLongImage: skuItem.subCategoryLongImage,
            mainImageUpload: skuItem.subCategoryLongImage,
        }));
        
        const newFileList = urlsToUploadList([skuItem.subCategoryLongImage]);
        setFileList(newFileList);
    }
    const handleDeleteItem = async () => {
        if (inventoryStore.currentSKUMaster) {
            await inventoryStore.removeSKUs(inventoryStore.currentSKUMaster, false)
        }
    }

    const urlsToUploadList = (urls: string[]) => {
        return urls.map<UploadFile>(x => ({
            uid: x,
            url: x,
            name: x,
        }));
    }

    React.useEffect(() => {
        if (item) {
            const newFileList = urlsToUploadList([item?.subCategoryLongImage, ...(item?.productImages ?? [])]);
            setFileList(newFileList);
            setSafetyFiles(urlsToUploadList(item?.safetyDataSheetUrl ?? []));
            setTechnicalFiles(urlsToUploadList(item?.technicalSpecificationFiles ?? []));
        }
    }, [item])

      const onChange = (editorState:string,editor?:LexicalEditor) => {
        // Update local state with the editor's current state
        editor?.update(() => {
            const editorState = editor?.getEditorState();
            const jsonString = JSON.stringify(editorState.toJSON());
            const htmlString = $generateHtmlFromNodes(editor, null);
            setEditorStateJSON(jsonString);
            setEditorStateHTML(htmlString);
        });
    };


    const handleSave = async () => {
        // Call updateEditorWithHtml before saving
        editorRef.current?.updateEditorWithHtml();
      
        const currentEditorStateJSON = await new Promise<string>((resolve) => {
          setEditorStateJSON((jsonString) => {
            resolve(jsonString);
            return jsonString;
          });
        });
      
        const currentEditorStateHTML = await new Promise<string>((resolve) => {
          setEditorStateHTML((htmlString) => {
            resolve(htmlString);
            return htmlString;
          });
        });
      
        if (item) {
            inventoryStore.updateSkuMasterEditorContent(item.id, editorStateJSON, editorStateHTML)
                .then(() => {
                    console.log("Content saved successfully");
                })
                .catch((error: any) => {
                    console.error("Failed to save content", error);
                });
        } else {
            console.error("Item ID is missing");
        }
    };
    return (
        <>
            <div className='form-options-container'>
                <Buttons.Text
                    text="Duplicate"
                    loading={false}
                    disabled={false}
                    onClick={() => setShowDuplicatePanel(true)}
                />
                {item && (
                    <PopupConfirm
                        title="Are you sure you want to delete this inventory item"
                        btnTitle="Delete"
                        onConfirm={() => handleDeleteItem()}
                        loading={false}
                        disabled={!item}
                    />
                )}
            </div>

            <Form
                ref={formRef}
                name='new-inventory'
                className='custom-form inventory-form'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout='vertical'
                requiredMark={false}
                validateMessages={validateMessages}
                initialValues={initialValues}
            >
                <Row>
                    <Col span={8}>
                    <Files.ImagesPreviewUpload
                        name='mainImages'
                        title={'Add Main Image'}
                        maxCount={10}
                        fileList={fileList}
                        selectedFile={selectedFile}
                        setFileList={(newFileList: any) => {
                            setFileList(newFileList);
                        }}
                        setSelectedFile={setSelectedFile}
                        onRemoveUrlImage={(url, idx) => {
                            setRemovedFiles(arr => [...arr, url]);
                        }}
                    />
                    </Col>

                    <Col span={16}>
                        {rowFormData.map((row, index) =>
                            <Row key={index}>
                                {row.map((item, index) => (
                                    <Col key={index} span={24 / row.length}>
                                        <CustomForm
                                            item={item}
                                            index={index}
                                            component={item.component}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </Col>
                </Row>

                {otherFormData.map((row, index) =>
                    <Row key={index}>
                        {row.map((item, index) => (
                            <Col key={index} span={24 / row.length}>
                                <CustomForm
                                    item={item}
                                    index={index}
                                    component={item.component}
                                />
                            </Col>
                        ))}
                    </Row>
                )}
            <Row align="middle">
                <Col>
                    <label>Similar Search Terms</label>
                </Col>
                <Col>
                    <Buttons.Text
                        text="Duplicate Search Terms"
                        loading={false}
                        disabled={false}
                        onClick={() => setShowDuplicateSearchTermsPanel(true)}
                    />
                </Col>
            </Row>

            <Form.Item name="searchTerms">
                <SearchTermsInput />
            </Form.Item>
                
                {radioFormData.map((row, index) =>
                    <Row key={index}>
                        {row.map((item, index) => (
                            <Col key={index} span={24 / row.length}>
                                <CustomForm
                                    item={item}
                                    index={index}
                                    component={item.component}
                                />
                            </Col>
                        ))}
                    </Row>
                )}

                <h3 className='form-header'>Documents</h3>

                <div className='inventory-form-small-header'>Add Safety Data Sheets</div>
                <div className='inventory-form-document-list'>
                    <Files.CustomUpload
                        accept='application/pdf'
                        fileList={safetyFiles}
                        setFileList={setSafetyFiles}
                        multiple={true}
                        listType="picture-card"
                        previewFile={undefined}
                        onRemove={(file) => {
                            if (file?.url) {
                                setRemovedFiles(arr => [...arr, file.url ?? '']);
                            }
                        }}
                        children={() => (
                            <div className='custom-files-upload-indicator'>
                                <PlusOutlined />
                            </div>
                        )}
                    />
                </div>

                <div className='inventory-form-small-header'>Add Technical Specification Files</div>
                <div className='inventory-form-document-list'>
                    <Files.CustomUpload
                        accept='application/pdf'
                        fileList={technicalFiles}
                        setFileList={setTechnicalFiles}
                        multiple={true}
                        listType="picture-card"
                        previewFile={undefined}
                        onRemove={(file) => {
                            if (file && file.url) {
                                setRemovedFiles(arr => [...arr, file.url ?? '']);
                            }
                        }}
                        children={() => (
                            <div className='custom-files-upload-indicator'>
                                <PlusOutlined />
                            </div>
                        )}
                    />
                </div>
                <div className="con-lex" >
                    <h3 className='form-header'>Text-editor</h3>
                    <>
                        <HtmlEditor ref={editorRef} onChange={onChange} {...(editorStateJSON && { initialEditorState: editorStateJSON })} />
                        <br />
                        <Buttons.Small
                            htmlType='button'
                            text="Save"
                            onClick={handleSave}
                        />
                    </>
                </div>

                <div className='custom-form-submit'>
                    <Buttons.Small
                        htmlType='submit'
                        text={item ? 'Update inventory' : 'Add inventory'}
                        loading={isLoading}
                        disabled={isLoading}
                    // multiple={true}
                    />
                </div>
            </Form>
            <Drawers.CustomDrawer
                title="Duplicate item"
                visible={showDuplicatePanel}
                setVisible={(_) => setShowDuplicatePanel(_)}
                children={() => (
                    <DuplicateSkuItem
                        onFormFinish={(skuItem: ISkuMaster) => {
                            handleDuplicateFromItem(skuItem)
                                .then(res => setShowDuplicatePanel(false));
                        }}
                    />
                )}
            />

            <Drawers.CustomDrawer
                title="Duplicate Search Terms"
                visible={showDuplicateSearchTermsPanel}
                setVisible={setShowDuplicateSearchTermsPanel}
                children={() => (
                    <DuplicateSkuItem
                        onFormFinish={(skuItem: ISkuMaster) => {
                            handleDuplicateSearchTerms(skuItem);
                        }}
                    />
                )}
            />
        </>
    );
});

export default NewSkuItem;
