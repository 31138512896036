import { Layout, Menu } from "antd";
import { NavLink, useHistory } from "react-router-dom";
import { Layouts } from "../../utils/enums";
import { RiLogoutCircleLine } from "react-icons/ri"
import "./style.css";
import React from "react";
import { AppLogo } from "../../assets";
import { useAuthContext } from "../../context/AuthContext";

type SiderMainProp = {
    collapsed: boolean
    routes: any,
    layout?: Layouts
}

const styles = {
    iconFlex: { display: 'flex', alignItems: 'center' },
    icon: { alignItems: 'center' },
    spacing: { marginLeft: 26, marginBottom: 5 }
};

const Main = ({ collapsed, routes, layout }: SiderMainProp) => {
    const [activePath, setActivePath] = React.useState('');
    const { hasRoutePermission, logoutUser } = useAuthContext();
    const history = useHistory();

    const activeRoute = (routeName: any) =>
        window.location.href.indexOf(routeName) > -1
            ? setActivePath(routeName)
            : '';

    const generateMenuItems = (defaultRoutes: any[]) =>
        defaultRoutes.map((prop, index) => {
            if (prop.layout !== Layouts.Home && !prop.icon && !prop.collapse) {
                return null;
            } else if (!hasRoutePermission(prop.platformRights)) {
                return null;
            }
            
            if (prop.icon === undefined && prop.name === "") {
                return null;
            }
            return (
                <Menu.Item
                    style={collapsed ? (styles.icon) : (styles.iconFlex)}
                    key={prop.path}
                    icon={prop.icon}
                >
                    <NavLink
                        style={styles.spacing}
                        to={prop.layout + prop.path}
                        activeClassName="activeLink"
                        className='sidermain-menu-link'
                    >
                        {prop.name}
                    </NavLink>
                </Menu.Item>
            );
        });

    const handleLogout = () => {
        logoutUser();
    }

    React.useEffect(() => {
        routes.map((prop: any) => {
            if (prop.layout !== Layouts.Home && !prop.collapse) return null;

            return activeRoute(prop.path);
        });
    }, [routes]);

    return (
        <Layout.Sider
            collapsible
            collapsed={collapsed}
            trigger={null}
            className='sidermain'
            width={280}
        >
            <div className='sidermain-logo-container'>
                <img
                    src={AppLogo}
                    alt="G.Fox logo"
                    className={[
                        'sidermain-logo',
                        collapsed && 'sidermain-logo-collapsed',
                    ].join(' ')}
                />
            </div>

            <Menu
                className='sidermain-menu'
                mode='inline'
                theme="light"
                defaultSelectedKeys={[activePath]}>
                {generateMenuItems(routes)}
                <Menu.Item
                    key={'logout'}
                    style={collapsed ? (styles.icon) : (styles.iconFlex)}
                    className='sidermain-logout'
                    icon={<RiLogoutCircleLine className='menu-icon' />}
                >
                    <NavLink
                        style={styles.spacing}
                        to={'/'}
                        activeClassName="activeLink"
                        className='sidermain-menu-link'
                        onClick={handleLogout}
                    >
                        Log out
                    </NavLink>
                </Menu.Item>
            </Menu>
        </Layout.Sider>
    );
}

export default Main;