import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Store } from "../../stores/stores";
import { Form, message, Select } from "antd";
import { IEmailNotification } from "../../utils/interfaces";
import { EmailType } from "../../utils/enums";
import { Buttons } from "..";

interface EmailNotificationFormProps {
    emailType: EmailType | undefined;
}

const EmailNotificationForm = observer(({ emailType }: EmailNotificationFormProps) => {
    const [form] = Form.useForm();
    const { emailNotificationStore } = Store;
    const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
    const [initialLoadDone, setInitialLoadDone] = useState(false);

    useEffect(() => {
        if (emailType && !initialLoadDone) {
            (async () => {
                console.log("Emails type:", emailType);
                await emailNotificationStore.loadEmails(emailType);
                setSelectedEmails(
                    emailNotificationStore.emailNotifications.value.data.map((email) => email.emailAddress)
                );
                setInitialLoadDone(true);
            })();
        }
    }, [emailType]);

    const handleEmailChange = (value: string[]) => {
        setSelectedEmails(value);
    };

    const handleSubmit = async () => {
        const currentEmails = emailNotificationStore.emailNotifications.value.data;
        const newEmailNotifications = selectedEmails.map(email => ({
            emailAddress: email,
            emailTypeEnum: emailType,
        } as IEmailNotification));

        const uniqueEmails = newEmailNotifications.filter(
            email => !currentEmails.some(existingEmail => existingEmail.emailAddress === email.emailAddress)
        );

        const removedEmails = currentEmails.filter(
            existingEmail => !selectedEmails.includes(existingEmail.emailAddress)
        );

        await Promise.all(uniqueEmails.map(email => emailNotificationStore.onFormSubmit(email)));

        await Promise.all(removedEmails.map(email => emailNotificationStore.deleteEmail(email)));

        form.resetFields();
        setInitialLoadDone(false);
    };

    return (
        <Form form={form} onFinish={handleSubmit}>
            <Form.Item label="Select or Add Emails">
                <Select
                    mode="tags"
                    style={{ width: "100%" }}
                    placeholder="Select or add emails"
                    value={selectedEmails}
                    onChange={handleEmailChange}
                >
                    {emailNotificationStore.emailNotifications.value.data.map((email) => (
                        <Select.Option key={email.id} value={email.emailAddress}>
                            {email.emailAddress}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item>
                <Buttons.Small
                    htmlType="submit"
                    loading={emailNotificationStore.isLoading}
                    text="Update Emails"
                />
            </Form.Item>
        </Form>
    );
});

export default EmailNotificationForm;
