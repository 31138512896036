import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Table, message } from "antd";
import { Buttons, Spinners } from "..";
import { ICategorySpecificInfo, IInventoryMaster, IProductClass, IStockUom } from "../../utils/interfaces";
import { Store } from "../../stores/stores";

export type InventoryTableProp = {
    inventory: IInventoryMaster[];
    isLoading: boolean;
    editInventory: (inv: IInventoryMaster) => void;
    addToGoogleMerchant: (id: string[]) => void;
}
 const {inventoryStore} = Store;
const InventoryTable = ({ inventory, isLoading, editInventory , addToGoogleMerchant }: InventoryTableProp) => {
    const columns = [
        {
            title: "Sku",
            dataIndex: "sku",
            fixed: 'left' as 'left',
            width: 120,
        },
        {
            title: "Description",
            dataIndex: "description",
        },
        {
            title: "App-only Pricing",
            dataIndex: "appPrice",
            render: (text?: number) =>  'R' + (text ?? '0.00')
        },
        {
            title: "Web Reference",
            dataIndex: "webReference",
        },
        {
            title: "Last Sale",
            dataIndex: "dateLastSale",
        },
        {
            title: "Last Purchase",
            dataIndex: "dateLastPurchase",
        },
        {
            title: "Stock UOM",
            dataIndex: "stockUom",
            render: (text: IStockUom) => text?.name
        },
        {
            title: "Product Class",
            dataIndex: "productClass",
            render: (text: IProductClass) => text?.name
        },
        {
            "title": "Link to Product",
            "dataIndex": "",
            render: (text: string, record: IInventoryMaster) => {
                const fixedLink = `${process.env.REACT_APP_WEBSITE_DOMAIN}/products/`;
                const categoryRefNo = inventoryStore.currentSKUMaster?.category.refNo.slice(0,inventoryStore.currentSKUMaster?.category.refNo.length-1) ;
                // const sku = record.skuNavigation.sku;
                const stockCode = record.sku;
                const copyToClipboard = async () => {
                    if(categoryRefNo !== undefined){
                         const result =  await inventoryStore.getParentCategory(categoryRefNo);
                         const refNo = inventoryStore.currentSKUMaster?.category.refNo.slice(0,inventoryStore.currentSKUMaster?.category.refNo.length-1)
                        const link = `${fixedLink}${result?.refNo}/${refNo}/${inventoryStore.currentSKUMaster?.sku}?stockCode=${stockCode}`;
                        navigator.clipboard.writeText(link)
                          .then(() => message.success('Copied to clipboard'))
                        .catch(err => message.error('Error copying link to clipboard: ',));
                    }
                };

                return <Buttons.Small className="copy-btn"  htmlType="submit" text={'Copy to Clipboard'} onClick={copyToClipboard}></Buttons.Small>
            }
        },
        {
            title: "Catalogue Page",
            dataIndex: "cataloguePage",
        },
        {
            title: "New Web",
            dataIndex: "newweb",
        },
        {
            title: "Category Specific Information",
            dataIndex: "categorySpecificInfo",
            render: (text: ICategorySpecificInfo[], record: any) => (
                <>
                    {text?.map((info, index) => (
                        <div key={index} className='custom-table-address-field'>
                            {`${info?.name} : ${info?.value}`}
                        </div>
                    ))}
                </>
            )
        },
        {
            title: "Active",
            dataIndex: "active",
            render: (text: boolean, record: any) => (
                text ? (
                    <CheckOutlined style={{ color: 'green' }} />
                ) : (
                    <CloseOutlined style={{ color: 'red' }} />
                )
            )
        },
        {
            title: "Created",
            dataIndex: "createdAt",
        },
        {
            key: 'edit',
            fixed: 'right' as 'right',
            width: 90,
            render: (text: any, record: any) => (
                <Buttons.Text
                    text='Edit'
                    onClick={() => {
                        editInventory(record)
                    }}
                />
            )
        },
        {
            title: "Add to Google Merchant",
            dataIndex: "id",
            width: 100,
            fixed: 'right' as 'right',
            render: (id: string) => (
              <Buttons.Text text="Add to GM" onClick={() => addToGoogleMerchant([id])}
              />
            ),
          },
    ];

    return (
        <Spinners.CustomSpinner
            title="Getting Inventory"
            isSpinning={isLoading}
        >
            <Table
                rowKey='id'
                className='expanded-table expanded-table-inventory'
                columns={columns}
                dataSource={[...inventory]}
                scroll={{ x: 3000 }}
                pagination={{
                    pageSize: 4,
                    style: { margin: '16px' },
                    // position: ['topRight', 'bottomRight']
                }}
            />
        </Spinners.CustomSpinner>
    );
};

export default InventoryTable;