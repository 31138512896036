import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Col, Form, FormInstance, Input, message, Row, InputNumber, DatePicker, Switch, Space, Empty, Select } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { Buttons, Drawers, ExpandedTables, Files, Search } from "..";
import { copyObjects } from "../../services";
import { Store } from "../../stores/stores";
import { ICategorySpecificInfo, IInventoryMaster, ISkuMaster } from "../../utils/interfaces";
import { PopupConfirm } from "../Modals";
import { DebounceSelectProp } from "../Search/DebounceSearch";
import DuplicateInventoryItem from "./DuplicateInventoryItem";
import { CustomForm } from "./NewSkuItem";
import "./style.css";

type NewInventoryItemProp = {
    sku?: IInventoryMaster,
    parentSku?: ISkuMaster,
    onFormFinish: (inv: FormData) => void,
    isLoading?: boolean
};

const NewInventoryItem = observer(({ sku, parentSku, isLoading, onFormFinish }: NewInventoryItemProp) => {
    const [fileList, setFileList] = React.useState<UploadFile[]>([]);
    const [removedFiles, setRemovedFiles] = React.useState<string[]>([]);
    const [selectedFile, setSelectedFile] = React.useState(0);
    const [showDuplicatePanel, setShowDuplicatePanel] = React.useState(false);
    const formRef = React.createRef<FormInstance>();
    const [showDuplicateSearchTermsPanel, setShowDuplicateSearchTermsPanel] = React.useState(false);

    const { branchesStore, inventoryStore } = Store;
    const branches = branchesStore.branches.value.data;

    const initialValues = copyObjects(inventoryStore.currentInventory, {
        parentSkuName: parentSku?.sku,
        dateLastPurchase: sku?.dateLastPurchase ? moment(sku.dateLastPurchase) : moment(),
        stockUomName: sku?.stockUom?.name,
        productClassName: sku?.productClass?.name,
    });

    const validateMessages = {
        required: "Field is required!",
    };

    const selectParentSku = ({ ...props }: any) => (
        <Search.DebounceSearch<DebounceSelectProp>
            fetchOptions={(_) => inventoryStore.searchComplementaryItems(_, true)}
            {...props}
        />
    );

    const selectStockUom = ({ ...props }: any) => (
        <Search.DebounceSearch<DebounceSelectProp>
            fetchOptions={(_) => inventoryStore.searchStockUoms(_)}
            {...props}
        />
    );

    const selectProductClass = ({ ...props }: any) => (
        <Search.DebounceSearch<DebounceSelectProp>
            fetchOptions={(_) => inventoryStore.searchProductClasses(_)}
            {...props}
        />
    );
    const SearchTermsInput = ({ ...props }: any) => (
        <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Add search terms"
            {...props}
        />
    );

    const handleDuplicateSearchTerms = async (invItem: IInventoryMaster) => {
        formRef.current?.setFieldsValue({
            searchTerms: invItem.searchTerms ?? [],
        });
        setShowDuplicateSearchTermsPanel(false);
    };


    const rowFormData = [
        [
            { name: 'sku', label: "Sku", component: Input, required: true },
            { name: 'parentSkuName', label: "Parent Sku", component: selectParentSku },
        ],
        [
            { name: 'description', label: "Description", component: Input },
        ],
    ];

    const otherFormData = [
        [
            { name: 'appPrice', label: "App Pricing", component: InputNumber },
            { name: 'webReference', label: "Web Reference", component: Input },
        ],
        [
            // { name: 'dateLastPurchase', label: "Date Last Purchase", component: DatePicker },
            { name: 'cataloguePage', label: "CataloguePage", component: InputNumber },
            { name: 'newweb', label: "New Web", component: Input },
        ],
        [
            // { name: 'stockUomName', label: "Stock Uom", component: selectStockUom },
            { name: 'productClassName', label: "Product Class", component: selectProductClass },
        ],
    ];

    const radioFormData = [
        [
            { name: 'lengthInMm', label: "Length (mm)", component: InputNumber },
            { name: 'widthInMm', label: "Width (mm)", component: InputNumber },
            { name: 'heightInMm', label: "Height (mm)", component: InputNumber },
            { name: 'weightInG', label: "WeightIn (g)", component: InputNumber },
        ],
        [
            { name: 'active', label: "Active", component: Switch, valuePropName: "checked", required: false },
        ]
    ];

    const categoryFormData = [
        { name: 'name', placeholder: "Name", component: Input, },
        { name: 'value', placeholder: "Value", component: Input, },
    ];

    const onFinish = (values: any) => {
        let formData = new FormData();
        let formValues = Object.assign({}, sku, values, {
            skuId: values?.parentSkuName === initialValues?.parentSkuName ? parentSku?.id : values?.parentSkuName,
            dateLastPurchase: moment(values.dateLastPurchase).toISOString(),
            stockUomId: values.stockUomName === initialValues.stockUomName ? sku?.stockUomId : values.stockUomName,
            productClassId: values.productClassName === initialValues.productClassName ? sku?.productClassId : values.productClassName
        });

        if (!formValues.skuId) {
            message.error("Parent Sku value required")
            return
        }
        
        if (sku) {
            formData.append('id', sku.id)
        }

        if (fileList.length > 0) {
            Object.keys(formValues).forEach(key => {
                if (key === 'categorySpecificInfo' && formValues[key]) {
                    formValues[key].forEach((x?: ICategorySpecificInfo) => {
                        if (x) {
                            formData.append('serializedCategoryInfo', JSON.stringify(x));
                        }
                    });
                } else if (Array.isArray(formValues[key])) {
                    formValues[key].forEach((x?: any) => x && formData.append(key, x));
                } else if (formValues[key]) {
                    formData.append(key, formValues[key]);
                }
            });

            fileList.forEach((file) => {
                if (file.originFileObj) {
                    formData.append('imagesToUpload', file.originFileObj);
                }
            });
            removedFiles.forEach(url => formData.append('imagesToDelete', url))
            onFormFinish(formData);
        } else {
            message.error('Add product images.');
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        if (!sku) {
            message.error('Could not add inventory.')
        } else {
            message.error('Could not update inventory.')
        }
    };
    const urlsToUploadList = (urls: string[]) => {
        return urls.map<UploadFile>(x => ({
            uid: x,
            url: x,
            name: x,
        }));
    }

    const handleDuplicateFromItem = async (invItem: IInventoryMaster) => {
        const currentSkuValue = formRef.current?.getFieldValue("sku");
        formRef.current?.setFieldsValue(copyObjects(invItem, {
            sku: currentSkuValue,
            stockUomName: invItem?.stockUom?.name,
            productClassName: invItem?.productClass?.id, //TODO: user friendly value
            productImages: invItem?.productImages,
        }));
        
        const newFileList = urlsToUploadList(invItem?.productImages);
        setFileList(newFileList);
    }

    const handleDeleteItem = async () => {
        if (inventoryStore.currentInventory) {
            await inventoryStore.removeInventory(inventoryStore.currentInventory, false)
        }
    }

    React.useEffect(() => {
        const productImages = inventoryStore.currentInventory?.productImages;
        const images = productImages?.map(src => (
            {
                uid: src,
                url: src,
                name: src
            } as UploadFile)
        );

        setFileList(images || []);
    }, [inventoryStore.currentInventory?.productImages])

    return (
        <>
            <div className='form-options-container'>
                <Buttons.Text
                    text="Duplicate"
                    loading={false}
                    disabled={false}
                    onClick={() => setShowDuplicatePanel(true)}
                />
                {sku && (
                    <PopupConfirm
                        title="Are you sure you want to delete this inventory item"
                        btnTitle="Delete"
                        onConfirm={() => handleDeleteItem()}
                        loading={false}
                        disabled={!sku}
                    />
                )}
            </div>

            <Form
                ref={formRef}
                name='new-inventory'
                className='custom-form inventory-form'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout='vertical'
                requiredMark={false}
                validateMessages={validateMessages}
                initialValues={initialValues}
            >
                <h3 className='form-header'>Display name</h3>
                <h2 className='form-header'>{parentSku?.productName}</h2>

                <Row>
                    <Col span={8}>
                        <Files.ImagesPreviewUpload
                            name='mainImages'
                            title={'Add Images'}
                            maxCount={8}
                            multiple={true}
                            fileList={fileList}
                            selectedFile={selectedFile}
                            setFileList={(newFileList: any) => {
                                setFileList(newFileList);
                            }}
                            setSelectedFile={setSelectedFile}
                            onRemoveUrlImage={(url) => setRemovedFiles(arr => [...arr, url])}
                        />
                    </Col>

                    <Col span={16}>
                        {rowFormData.map((row, index) =>
                            <Row key={index}>
                                {row.map((item, index) => (
                                    <Col key={index} span={24 / row.length}>
                                        <CustomForm
                                            item={item}
                                            index={index}
                                            component={item.component}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </Col>
                </Row>

                {otherFormData.map((row, index) =>
                    <Row key={index}>
                        {row.map((item, index) => (
                            <Col key={index} span={24 / row.length}>
                                <CustomForm
                                    item={item}
                                    index={index}
                                    component={item.component}
                                />
                            </Col>
                        ))}
                    </Row>
                )}
                 <Row align="middle">
                <Col>
                    <label>Similar Search Terms</label>
                </Col>
                <Col>
                    <Buttons.Text
                        text="Duplicate Search Terms"
                        loading={false}
                        disabled={false}
                        onClick={() => setShowDuplicateSearchTermsPanel(true)}
                    />
                </Col>
            </Row>

            <Form.Item name="searchTerms">
                <SearchTermsInput />
            </Form.Item>
                
                {radioFormData.map((row, index) =>
                    <Row key={index}>
                        {row.map((item, index) => (
                            <Col key={index} span={24 / row.length}>
                                <CustomForm
                                    item={item}
                                    index={index}
                                    component={item.component}
                                />
                            </Col>
                        ))}
                    </Row>
                )}

                <Form.List name="categorySpecificInfo">
                    {(fields, { add, remove }) => (
                        <>
                            <h3 className='form-header'>
                                Product Specific Information
                                <PlusOutlined onClick={() => add()} className='inventory-form-category-add' />
                            </h3>

                            <p>Product specific information, e.g. Color: Red</p>

                            {fields.length === 0 ? (
                                <Empty />
                            ) : (
                                fields.map(({ key, name, fieldKey, ...restField }: any) => (
                                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                        <Row>
                                            {categoryFormData.map((row, index) =>
                                                <Col key={index} span={12}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, row.name]}
                                                        fieldKey={[fieldKey, row.name]}
                                                        rules={[{ required: true }]}
                                                    >
                                                        <Input placeholder={row.placeholder} />
                                                    </Form.Item>
                                                </Col>
                                            )}
                                        </Row>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))
                            )}
                        </>
                    )}
                </Form.List>

                {sku && (
                    <ExpandedTables.StockAvailabilityTable
                        isLoading={inventoryStore.isLoadingWareHouseInvetory}
                        branches={[...branches]}
                        warehouseInventory={inventoryStore.currentWarehouseInventory}
                    />
                )}

                <div className='custom-form-submit'>
                    <Buttons.Small
                        htmlType='submit'
                        text={inventoryStore.invPanelTitle}
                        loading={inventoryStore.isLoadingInventory}
                        disabled={inventoryStore.isLoadingInventory}
                    />
                </div>
            </Form>
            <Drawers.CustomDrawer
                title="Duplicate item"
                visible={showDuplicatePanel}
                setVisible={(_) => setShowDuplicatePanel(_)}
                children={() => (
                    <DuplicateInventoryItem
                        onFormFinish={(invItem: IInventoryMaster) => {
                            handleDuplicateFromItem(invItem)
                                .then(res => setShowDuplicatePanel(false));
                        }}
                    />
                )}
            />
             <Drawers.CustomDrawer
                title="Duplicate Search Terms"
                visible={showDuplicateSearchTermsPanel}
                setVisible={setShowDuplicateSearchTermsPanel}
                children={() => (
                    <DuplicateInventoryItem
                        onFormFinish={(invItem: IInventoryMaster) => {
                            handleDuplicateSearchTerms(invItem);
                        }}
                    />
                )}
            />
        </>
    );
});

export default NewInventoryItem;