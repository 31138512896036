import { APP_URL } from "../../config/axios-config";
import { EmailType } from "../../utils/enums";
import { IEmailNotification} from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getEmails = async (type?: EmailType): Promise<ResponseFormat<IEmailNotification>> => {
    const response = await APP_URL.get(`EmailNotification?type=${type}`);

    let emails: IEmailNotification[] = [];

    if (response?.data) {
        emails = response.data;
    }
    return {
        data: emails
    };
};

export const addEmail = async (email: IEmailNotification, emails: IEmailNotification[]): Promise<ResponseFormat<IEmailNotification>> => {
    const response = await APP_URL.post('EmailNotification', email);

    emails.push(response.data as IEmailNotification);
    return { data: emails };
};

export const updateEmail = async (email: IEmailNotification, emails: IEmailNotification[]): Promise<ResponseFormat<IEmailNotification>> => {
    const response = await APP_URL.put(`EmailNotification/${email.id}`, email);

    const updatedEmail = response.data as IEmailNotification;
    const index = emails.findIndex(e => e.id === updatedEmail.id);
    
    if (index !== -1) {
        emails[index] = updatedEmail;
    }
    return { data: emails };
};

export const deleteEmail = async (email: IEmailNotification, emails: IEmailNotification[]): Promise<ResponseFormat<IEmailNotification>> => {
    await APP_URL.delete(`EmailNotification/${email.id}`);

    const updatedEmails = emails.filter(e => e.id !== email.id);
    return { data: updatedEmails };
};
