import { APP_URL } from "../../config/axios-config";
import { IProductClass } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getProductClasses = async (queryParameters?: string): Promise<ResponseFormat<IProductClass>> => {
    const response = await APP_URL.get('productClass?' + queryParameters || '');

    const items: IProductClass[] = response.data;
    return { data: items };
}

export const addProductClass = async (body: IProductClass, items: IProductClass[]): Promise<ResponseFormat<IProductClass>> => {
    const response = await APP_URL.post('productClass', body);

    items.push(response.data as IProductClass);
    return { data: items };
}

export const updateProductClass = async (body: IProductClass, items: IProductClass[]): Promise<ResponseFormat<IProductClass>> => {
    items = [...items]

    await APP_URL.put(`productClass/${body.id}`, body);

    const elementPos = items.map(x => x.id).indexOf(body.id);
    items[elementPos!] = body;
    return { data: items };
}

export const deleteProductClass = async (body: IProductClass, items: IProductClass[]): Promise<ResponseFormat<IProductClass>> => {
    await APP_URL.delete(`productClass/${body.id}`);

    return { data: items };
}

export const addInventoryToGoogleMerchant = async (body: { inventoryMasterIds: string[] }): Promise<ResponseFormat<object>> => { 
    const response = await APP_URL.post(`ProductClass/AddInventoryToGoogleMerchant`, body);

    return { data: response.data };
}