import { Modal, Select, Spin, Button } from "antd";
import { EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Store } from "../../stores/stores";
import { ISurveyResponse, ISurveyResponseUpdate } from "../../utils/interfaces";
import "./style.css";
import { VerdictStatus, QuestionType, TypeId } from "../../utils/enums";

const verdictMap = {
    [VerdictStatus.Pending]: 'Pending',
    [VerdictStatus.Accepted]: 'Accepted',
    [VerdictStatus.Rejected]: 'Rejected',
};

const questionTypeMap = {
    [QuestionType.Email]: 0,
    [QuestionType.CV]: 1,
    [QuestionType.Name]: 2,
};

const baseFileUrl = "https://gfox-cdn.bridgelabs.design/hr/careers";

const SurveyResponseDrawer = observer(() => {
    const { surveyResponseStore, surveyQuestion } = Store;
    const response = surveyResponseStore.currentSurveyResponse;
    const allQuestions = surveyQuestion.surveyQuestions.value.data;

    useEffect(() => {
        if (!allQuestions.length) {
            surveyQuestion.loadSurveyQuestions();
        }
    }, []);

    if (surveyQuestion.surveyQuestions.isLoading) {
        return <Spin size="large" />;
    }

    if (!response) {
        return <div>No response selected</div>;
    }

    if (!response.response || response.response.length === 0) {
        return <div>No answers provided for this response.</div>;
    }

    const findAnswerByType = (questionType: any) => {
        const answer = response.response.find(a => a.type === questionType);
        console.log('Question Type : ', questionType);
        return answer ? answer.value[0] : "No answer provided";
    };


    const surveyQuestions = allQuestions.filter(q => q.surveyId === response.surveyId);
    const sortedQuestions = [...surveyQuestions].sort((a, b) => a.position - b.position);

    const emailAnswer = findAnswerByType(questionTypeMap[QuestionType.Email]);
    const nameAnswer = findAnswerByType(questionTypeMap[QuestionType.Name]);
    console.log("emailAnswer", emailAnswer);
    console.log("nameAnswer", nameAnswer);
    const handleVerdictChange = (id: string, newVerdict: string) => {
        Modal.confirm({
            title: "Confirm Verdict Change",
            content: "Are you sure you want to change the verdict for this response?",
            onOk: async () => {
                const existingResponse = surveyResponseStore.surveyResponses.value.data.find(r => r.id === id);
                if (!existingResponse) return;
                console.log(existingResponse);
                console.log("verdict: ", newVerdict);

                const updatedResponse: ISurveyResponseUpdate = {
                    id: existingResponse.id,
                    verdict: newVerdict as VerdictStatus,
                    recipientEmail: findAnswerByType(questionTypeMap[QuestionType.Email]),
                    recipientName: findAnswerByType(questionTypeMap[QuestionType.Name]),
                };
                console.log("Updated Response:  ",updatedResponse);
                try {
                    console.log("Updated Response:  ",updatedResponse);
                    await surveyResponseStore.updateSurveyResponse(id, updatedResponse);
                    await surveyResponseStore.loadSurveyResponses(); // Refresh responses after update
                    surveyResponseStore.setShowResponseDrawer(false);
                } catch (e) {
                    console.error('Error updating survey response', e);
                }
            },
        });
    };

    const constructFileUrl = (responseId: string, fileName: string) => {
        return `${baseFileUrl}/${responseId}/${fileName}`;
    };
    
    const handleFilePreview = (fileName: string) => {
        const fileUrl = constructFileUrl(response.id, fileName);
        window.open(fileUrl, '_blank');
    };
    
    return (
        <div className="survey-response-drawer">

            {sortedQuestions.map((question, index) => {
                const answer = response.response.find(a => a.questionId === question.id);
                const isFileQuestion = question.questionTypeId === TypeId.Document;
                return (
                    <div key={question.id} className="survey-response-card">
                        <div className="survey-response-question">
                            Question {index + 1}: {question.text}
                        </div>
                        <textarea
                            className="survey-response-answer"
                            value={(answer?.value || []).join(", ") || "No answer provided"}
                            readOnly
                        />
                        {isFileQuestion && (
                            <div className="file-preview-buttons">
                                {answer?.value.map((fileName: string, fileIndex:number ) => (
                                    <Button
                                        key={fileIndex}
                                        icon={<EyeOutlined />}
                                        onClick={() => handleFilePreview(fileName)}
                                        style={{ marginLeft: '10px' }}
                                    >
                                        View {fileName}
                                    </Button>
                                ))}
                            </div>
                        )}
                    </div>
                );
            })}

            <div className="survey-response-card">
                <div className="survey-response-question">Verdict</div>
                <Select
                    value={verdictMap[response.verdict as keyof typeof verdictMap]}
                    
                    onChange={(newVerdict) => handleVerdictChange(response.id, newVerdict)}
                >
                    <Select.Option value={VerdictStatus.Pending}>{verdictMap[0]}</Select.Option>
                    <Select.Option value={VerdictStatus.Accepted}>{verdictMap[1]}</Select.Option>
                    <Select.Option value={VerdictStatus.Rejected}>{verdictMap[2]}</Select.Option>
                </Select>
            </div>

            <div className="survey-response-card">
                <div className="survey-response-question">Submitted At</div>
                <textarea 
                    className="survey-response-answer"
                    value={response.createdAt}
                    readOnly 
                />
                <div className="survey-response-submitted">
                    Response ID: {response.id}
                </div>
            </div>
        </div>
    );
});

export default SurveyResponseDrawer;
