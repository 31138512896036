import { CheckOutlined, CloseOutlined, DownOutlined, MinusSquareOutlined, PlusSquareOutlined, } from "@ant-design/icons";
import { Button, Dropdown, Image, Input, Menu, Table, message } from "antd";
import React from "react";
import { RiSearchLine } from "react-icons/ri";
import { Buttons, Drawers, ExpandedTables, Forms, Menus, Spinners, Tags } from "../../../components";
import "./style.css";
import { observer } from "mobx-react-lite";
import { Store } from "../../../stores/stores";
import { ICategory, ISkuMaster } from "../../../utils/interfaces";
import { ExternalLink } from "../../../assets";
import { FALLBACK_IMAGE } from "../../../config/storage";

export const InventoryManagement = observer(() => {
    const { bannersStore, branchesStore, inventoryStore, specialsStore, vouchersStore, contentSliderStore, ImageMapStore, customPageStore } = Store;
    const categories = inventoryStore.categoryList;
    const inventory = inventoryStore.inventoryList
    const [expandedKey, setExpandedKey] = React.useState('');
    const [specialsModalVisible, setSpecialsModalVisible] = React.useState(false);

    const columns = [
        {
            "title": "Id",
            "dataIndex": "id",
            fixed: 'left' as 'left',
            width: 70,
            render: (text: any, record: any, index: number) => index + 1,
        },
        {
            "title": "Category",
            "dataIndex": "category",
            render: (text: ICategory, record: any, index: number) => (
                <Tags.CustomTag text={text?.name} />
            )
        },
        {
            "title": "Main Image",
            "dataIndex": "subCategoryLongImage",
            render: (text: any, record: any) => (
                text &&
                <Image
                    className='product-image'
                    alt="product"
                    preview={false}
                    src={text}
                    fallback={FALLBACK_IMAGE}
                />
            )
        },
        {
            "title": "SKU",
            "dataIndex": "sku",
        },
        {
            "title": "Product Name",
            "dataIndex": "productName",
        },
        {
            "title": "Full Description",
            "dataIndex": "fullDescription",
            render: (text: any, record: any) => (
                <div className='long-text'>{text}</div>
            )
        },
        {
            "title": "Safety Data Sheet",
            "dataIndex": "safetyDataSheetUrl",
            render: (text: string[], record: any) => (
                text?.map((src, index) => <ExternalLink key={index} src={src} />)
            )
        },
        {
    "title": "Link to Product",
    "dataIndex": "",
    render: (text: string, record: ISkuMaster) => {
        const fixedLink = `${process.env.REACT_APP_WEBSITE_DOMAIN}/products/`;
        const categoryRefNo = record.category?.refNo.slice(0, record.category.refNo.length - 1);
        const sku = record.sku;
        const copyToClipboard = async () => {
            if (!categoryRefNo) {
                message.error('Category reference number is missing');
                return;
            }
            const result = await inventoryStore.getParentCategory(categoryRefNo);
            if (!result?.refNo) {
                message.error('Parent category reference number is missing');
                return;
            }
            const link = `${fixedLink}${result.refNo}/${categoryRefNo}/${sku}`;
            navigator.clipboard.writeText(link)
                .then(() => message.success('Copied to clipboard'))
                .catch(err => message.error('Error copying link to clipboard'));
        };
        return <Buttons.Small className="copy-btn" htmlType="submit" text={'Copy to Clipboard'} onClick={copyToClipboard}></Buttons.Small>
    }
},

        {
            "title": "New",
            "dataIndex": "new",
            render: (isNew: boolean, record: any) => (
                isNew ? (
                    <CheckOutlined style={{ color: 'green' }} />
                ) : (
                    <CloseOutlined style={{ color: 'red' }} />
                )
            )
        },
        {
            "title": "Featured",
            "dataIndex": "featured",
            render: (isNew: boolean, record: any) => (
                isNew ? (
                    <CheckOutlined style={{ color: 'green' }} />
                ) : (
                    <CloseOutlined style={{ color: 'red' }} />
                )
            )
        },
        {
            "title": "Complementary Products",
            "dataIndex": "complementaryProducts",
            render: (text: string[], record: any) => (
                text?.map((sku, index) => <Tags.CustomTag text={sku} />)
            )
        },
        {
            "title": "Technical Specifications",
            "dataIndex": "technicalSpecificationFiles",
            render: (text: string[], record: any) => (
                text?.map((src, index) => <ExternalLink key={index} src={src} />)
            )
        },
        {
            "title": "Attributes",
            "dataIndex": "attributes",
            render: (text: string[], record: any) => (
                text?.map((attr, index) => <Tags.CustomTag text={attr} />)
            )
        },
        {
            "title": "Active",
            "dataIndex": "active",
            filters: [
                {
                    text: 'Active',
                    value: true,
                },
                {
                    text: 'Not Active',
                    value: false,
                },],
            onFilter: (value: any, record: ISkuMaster) => record.active === !!value,
            render: (isNew: boolean, record: any) => (
                isNew ? (
                    <CheckOutlined style={{ color: 'green' }} />
                ) : (
                    <CloseOutlined style={{ color: 'red' }} />
                )
            )
        },
        {
            "title": "createdAt",
            "dataIndex": "createdAt",
        },
        {
            key: 'edit',
            fixed: 'right' as 'right',
            width: 90,
            render: (text: any, record: any) => (
                <Buttons.Text
                    text='Edit'
                    onClick={() => {
                        inventoryStore.editSku(record);
                    }}
                />
            )
        },
        {
            key: 'add',
            fixed: 'right' as 'right',
            width: 90,
            render: (text: any, record: any) => (
                <Buttons.Text
                    text='Add'
                    onClick={() => {
                        inventoryStore.addNewInventory(record);
                    }}
                />
            )
        },

    ];

    const discountsMenu = (
        <Menu>
            <Menu.ItemGroup>
                <Menu.Item
                    key="vouchers"
                    onClick={() => {
                        vouchersStore.loadVouchers();
                        vouchersStore.togglePanel(true);
                    }}
                >
                    Vouchers
                </Menu.Item>
                   <Menu.Item
                    key="Loyalty"
                    onClick={() => {
                        vouchersStore.loadVouchers();
                        vouchersStore.toggleLoyaltyPanel(true);
                    }}
                >
                    Loyalty Card
                </Menu.Item>
            </Menu.ItemGroup>
        </Menu >
    );

    const bannersMenu = (
    <Menu>
        <Menu.ItemGroup>
            <Menu.Item
                key="banners_app"
                onClick={() =>  bannersStore.tooglePanel(true)}
            >
                Banners (App)
            </Menu.Item>
        </Menu.ItemGroup>

        <Menu.ItemGroup>
            <Menu.Item
                key="banners_web"
                onClick={() =>  contentSliderStore.tooglePanel(true)}
            >
                Banners (Website)
            </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup>
            <Menu.Item
                key="custom_pages"
                onClick={() =>  customPageStore.tooglePanel(true)}
            >
                Custom Pages
            </Menu.Item>
        </Menu.ItemGroup>
    </Menu >
    
    );

    const categoryMenu = (
        <Menu>
            <Menu.ItemGroup>
                <Menu.Item onClick={() => handleMenuClick()}>
                    All
                </Menu.Item>
            </Menu.ItemGroup>
            <Menus.CategoryMenu
                items={categories}
                key={'category-menu'}
                handleOnClick={(item) => handleMenuClick(item)}
            />
        </Menu >
    );

    const handleMenuClick = async (item?: ICategory) => {
        inventoryStore.setCategory(item);
        await inventoryStore.loadCategories();
        inventoryStore.setQuery('');
    }

    const addToGoogleMerchant = async (ids: string[]) => {
        try {
           inventoryStore.addToGoogleMerchant(ids);
        } catch (error) {
            message.error("An error occurred while adding the item to Google Merchant.");
            console.error(error);
        }
    };

    React.useEffect(() => {
        (async () => {
            await inventoryStore.loadCategories();
            branchesStore.loadBranches();
            inventoryStore.setQuery('');
        })()
    }, [inventoryStore, branchesStore])

    return (
        <main id="inventory-management" className='home-content-page'>
            <div className='pageheader-container'>
                <h1 className='header'>Inventory Management</h1>
                <div className='pageheader-options'>
                <div>
                        <Buttons.Small
                            className='dropdown-btn'
                            text="Product Map"
                            onClick={() => {
                                ImageMapStore.togglePanel(true);
                                ImageMapStore.loadImageMap();
                            }}
                        />
                    </div>
                    <div>
                        <Buttons.Small
                            className='dropdown-btn'
                            text="Specials"
                            onClick={() => {
                                specialsStore.togglePanel(true);
                                specialsStore.loadSpecials();
                            }}
                        />
                    </div>

                    <div>
                    {/* New dropdown for content management */}
                    <Dropdown
                            overlay={bannersMenu}
                            className='pageheader-options-categories'
                        >
                            <Button
                                className='dropdown-btn'
                            >
                               Manage Content <DownOutlined style={{ fontSize: '12px' }} />
                            </Button>
                        </Dropdown>
                    </div>

                    <div className="pageheader-seperator">
                        <Dropdown
                            overlay={discountsMenu}
                            className='pageheader-options-categories'
                            disabled={vouchersStore.isLoading}
                        >
                            <Button
                                className='dropdown-btn'
                            >
                                Discounts <DownOutlined style={{ fontSize: '12px' }} />
                            </Button>
                        </Dropdown>
                    </div>

                    <Dropdown
                        overlay={categoryMenu}
                        className='pageheader-options-categories'
                        disabled={inventoryStore.isLoading}
                    >
                        <Button className='dropdown-btn'>
                            {inventoryStore.currentCategory?.name || 'All'} <DownOutlined style={{ fontSize: '12px' }} />
                        </Button>
                    </Dropdown>


                    <Input
                        size='large'
                        className='pageheader-options-search'
                        placeholder="Search list..."
                        defaultValue={inventoryStore.query}
                        prefix={<RiSearchLine color='#CBD5E0' />}
                        disabled={inventoryStore.isLoading && !inventoryStore.isSearching}
                        onChange={(_) => {
                            inventoryStore.setQuery(_.target.value)
                        }}
                    />
                    <Buttons.Small
                        text={'Add new'}
                        disabled={inventoryStore.isLoading}
                        onClick={() => inventoryStore.addNewSku()}
                    />
                </div>
            </div>

            <div className='custom-table-container'>
                <Spinners.CustomSpinner
                    title='Getting Inventory'
                    isSpinning={inventoryStore.isLoading || branchesStore.isLoading}
                >
                    <Table
                        dataSource={[...inventoryStore.skuSearchList.data]}
                        columns={columns}
                        className='custom-table'
                        rowKey='id'
                        expandedRowRender={(props) => (
                            <ExpandedTables.InventoryTable
                                {...props}
                                inventory={inventory}
                                isLoading={inventoryStore.isLoadingInventory}
                                editInventory={(_) => {
                                    inventoryStore.editInventory(_);
                                }}
                                addToGoogleMerchant={(id) => addToGoogleMerchant(id)} 
                            />)}
                        onExpand={(expanded, record: ISkuMaster) => {
                            if (expanded) {
                                inventoryStore.setSKUMaster(record);
                                inventoryStore.loadInventory();
                            } else {
                                inventoryStore.setSKUMaster()
                            }
                        }}
                        expandable={{
                            expandedRowKeys: [expandedKey],
                            expandIcon: ({ expanded, onExpand, record }) =>
                                expanded ? (
                                    <MinusSquareOutlined onClick={e => setExpandedKey('')} />
                                ) : (
                                    <PlusSquareOutlined onClick={e => {
                                        onExpand(record, e);
                                        setExpandedKey(record?.id)
                                    }} />
                                )
                        }}
                        scroll={{ x: 3500 }}
                        pagination={{
                            style: { margin: '16px' }
                        }}
                    />
                </Spinners.CustomSpinner>
            </div>

            <Drawers.CustomDrawer
                title={inventoryStore.skuPanelTitle}
                visible={inventoryStore.showSkuPanel}
                className="skuPanelDrawer"
                setVisible={(_) => inventoryStore.resetSkuForm()}
                width="90%"
                children={() => (
                    <Forms.NewSkuItem
                        item={inventoryStore.currentSKUMaster}
                        isLoading={inventoryStore.isLoading}
                        onFormFinish={(sku) => inventoryStore.onSKUFormSubmit(sku)}
                    />
                )}
            />

            <Drawers.CustomDrawer
                title={inventoryStore.invPanelTitle}
                visible={inventoryStore.showInventoryPanel}
                setVisible={(_) => inventoryStore.resetInventoryForm()}
                children={() => (
                    <Forms.NewInventoryItem
                        sku={inventoryStore.currentInventory}
                        parentSku={inventoryStore.currentSKUMaster}
                        onFormFinish={(inv) => inventoryStore.onInventoryFormSubmit(inv)}
                        isLoading={inventoryStore.isLoadingInventory}
                    />
                )}
            />

            <Drawers.CustomDrawer
                title={'Banners'}
                visible={bannersStore.showPanel}
                setVisible={(_) => bannersStore.tooglePanel(_)}
                children={() => <Drawers.Banners />}
            />
            <Drawers.CustomDrawer 
                title={'Website Banners'}
                visible={contentSliderStore.showPanel}
                setVisible={(_) => contentSliderStore.tooglePanel(_)}
                children={() => <Drawers.BannerSlider />}
            />
        <Drawers.Vouchers />
        <Drawers.LoyaltyCardDrawer />
            <Drawers.Specials />
            <Drawers.NewProductsMap />
            <Drawers.CustomPageDrawer />
        </main>
    )
});
