import React, { useEffect } from "react";
import { DatePicker, Form, FormInstance, Input, message, Select } from "antd";
import { observer } from "mobx-react-lite";
import { Buttons } from "..";
import { EmailService, copyObjects } from "../../services";
import { ISurvey } from "../../utils/interfaces";
import { PopupConfirm } from "../Modals";
import { useAuthContext } from "../../context/AuthContext";
import { Store } from "../../stores/stores";
import "./style.css";
import HtmlEditor from "./Editor";
import { DefaultTemplate } from "../../utils/constant";
import moment from "moment";

const { TextArea } = Input;



type NewSurveyFormProp = {
    survey?: ISurvey,
    onFormFinish?: (survey: ISurvey) => void,
    onSurveyDelete: () => void,
    isLoading: boolean,
};

const NewSurveyForm = observer(({ survey, onFormFinish, onSurveyDelete, isLoading }: NewSurveyFormProp) => {
    const { branchesStore } = Store
    const { currentUser } = useAuthContext();
    const formRef = React.createRef<FormInstance>();
    const validateMessages = {
        required: "Field is required!",
    };


    useEffect(() => {
        branchesStore.loadBranches();
    }, []);
    const branchOptions = branchesStore.branches.value.data
        .map((branch: { id: string; branchName: string }) => ({
            value: branch.id,
            label: branch.branchName
        }));

    const handleEditorChange = (content: string) => {
        formRef.current?.setFieldsValue({ openingMessage: content });
    };


    const handleOnFinish = (formValues: any) => {
        const values: ISurvey = {
            name: formValues.name,
            openingMessage: formValues.openingMessage,
            closingMessage: formValues.closingMessage,
            createdById: currentUser?.id,
            jobSummary: formValues.jobSummary,
            branchId: formValues.branchId,
            closingDate: formValues.closingDate?.toISOString(), 
        } as ISurvey;
        if (survey) {
            values.id = survey.id;
        }
        onFormFinish!(values);
    };
    
    return (
        <>
            {survey && (
                <div className='form-delete-container'>
                    <PopupConfirm
                        isLoading={isLoading}
                        onConfirm={() => onSurveyDelete()}
                    />
                </div>
            )}

            <Form
                ref={formRef}
                name='new-survey'
                className='custom-form'
                onFinish={handleOnFinish}
                onFinishFailed={() => { }}
                layout='vertical'
                requiredMark={false}
                validateMessages={validateMessages}
                initialValues={{ ...survey, closingDate: survey?.closingDate ? moment(survey.closingDate) : undefined }}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Job Requirements"
                    name="openingMessage"
                    rules={[{ required: true }]}
                >
                {
                    (() => {
                        try {
                            const initialContent = JSON.parse(survey?.openingMessage || '{}');
                            return (
                                <HtmlEditor
                                    onChange={handleEditorChange}
                                    initialEditorState={survey?.openingMessage || DefaultTemplate}
                                />
                            );
                        } catch {
                            return (
                                <HtmlEditor
                                    onChange={handleEditorChange}
                                    initialEditorState={DefaultTemplate}
                                />
                            );
                        }
                    })()
                }
                </Form.Item>

                <Form.Item
                    label="Job Summary"
                    name="jobSummary"
                    rules={[{ required: true }]}
                >
                    <TextArea />
                </Form.Item>

                <Form.Item
                    label="Closing Message"
                    name="closingMessage"
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Branch"
                    name="branchId"
                    rules={[{ required: true, message: 'Please select a branch' }]}
                >
                    <Select
                        placeholder="Select a branch"
                        options={branchOptions}
                        loading={branchesStore.isLoading}
                    /> 
                </Form.Item>
                <Form.Item
                    label="Closing Date"
                    name="closingDate"
                    rules={[{ required: true, message: 'Please select a closing date' }]}
                >
                    <DatePicker
                        style={{ width: '100%' }}
                        disabledDate={(current) => current && current < moment().endOf('day')}
                    />
                </Form.Item>

                <div className='custom-form-submit'>
                    <Buttons.Small
                        htmlType='submit'
                        text={survey ? 'Update job' : 'Add job'}
                        loading={isLoading}
                        disabled={isLoading}
                    />
                </div>
            </Form>
        </>
    );
});

export default NewSurveyForm;

