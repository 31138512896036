import { observable, action, computed, makeObservable } from "mobx";
import { LoadManager } from "../utils/LoadManager/LoadManager";
import { getOrders, addOrder, deleteOrder, updateOrder, getOrdersCSV } from "../services/api-service/order";
import { IInventoryMaster, IMessage, IOrder, ISavedOrderInfo, IParcelLabel, IOrderMessage } from "../utils/interfaces";
import { message } from "antd";
import { ResponseFormat } from "../utils/types";
import moment, { Moment } from "moment";
import { APIService, copyObjects } from "../services";
import { ParcelItem } from "../components/ExpandedTables/ProductSpecificationTable";
import { downloadObject } from "../services/downloadObject";

type PickedDateRange = [Moment | null, Moment | null] | null;

type ExtendedInventoryList = IInventoryMaster & {
    orderInfo: ISavedOrderInfo
}

type ActiveTabsType = "1" | "2" | "3";

export class OrderStore {
    orders = new LoadManager<IOrder>({ data: [] }, getOrders, addOrder, deleteOrder, updateOrder);

    query = "";
    showPanel = false;
    showLabelsPanel = false;
    currentOrder: IOrder | null = null;
    inventoryList: ExtendedInventoryList[] = [];
    isLoadingAPI = false;
    dateRange: PickedDateRange = [moment().add(-31, "days"), moment()];
    currentLabels: IParcelLabel[] = [];
    activeTab: ActiveTabsType = "1";
    defaultPageSize = 50;
    defaultPageNumber = 1;
    startDate = "";
    endDate = "";

    constructor() {
        makeObservable(this, {
            orders: observable,
            query: observable,
            setQuery: action,
            getOrders: computed,
            showPanel: observable,
            showLabelsPanel: observable,
            toogleLabelsPanel: action,
            currentOrder: observable,
            inventoryList: observable,
            isLoadingAPI: observable,
            setLoadingAPI: action,
            fecthInventoryList: action,
            currentLabels: observable,
            setParcelLabels: action,
            dateRange: observable,
            setDateRange: action,
            panelTitle: computed,
            editOrder: action,
            resetForm: action,
            isLoading: computed,
            loadOrders: action,
            onFormSubmit: action,
            activeTab: observable,
            setActiveTab: action,
            statusHistory: computed,
            pagination: computed,
        })
    }

    setQuery(s: string) {
        this.query = s.trim();
    }

    get pagination() {
        return this.orders.value.pagination ?? {
            PageNumber: this.defaultPageNumber,
            PageSize: this.defaultPageSize,
            TotalPages: 1,
            TotalRecords: 1,
        };
    }

    get getOrders(): ResponseFormat<IOrder> {
     return this.orders.value;
    }

    isInDateRange(createdAt: string | Date | null): boolean {
        let [startAt, endAt] = this.dateRange!;
        startAt = moment(startAt).local().startOf("day")
        endAt = moment(endAt).local().endOf("day")
        return moment.utc(createdAt).isBetween(startAt, endAt);
    }

    setDateRange(range: PickedDateRange) {
        if (range && range[0] && range[1]) {
            this.dateRange = range;
        } else {
            message.error('Select date range.')
        }
    }

    toogleLabelsPanel(value: boolean) {
        this.showLabelsPanel = value;

        if (value) {
            this.loadLabels();
        }
    }

    get panelTitle(): string {
        return "Orders";
    }

    get statusHistory() {
        const history = this.currentOrder?.orderStatusHistories.slice() ?? [];
        return history.sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix());
    }

    setActiveTab(value: string | ActiveTabsType) {
        this.activeTab = value as ActiveTabsType;
    }

    editOrder(order?: IOrder | null, activeTab: ActiveTabsType = "1") {
        this.showPanel = true;
        this.currentOrder = order ?? null;
        this.fecthInventoryList();
        this.setActiveTab(activeTab);
    }

    resetForm() {
        this.showPanel = false;
        this.currentOrder = null;
    }

    get isLoading(): boolean {
        return this.orders.isLoading || this.isLoadingAPI;
    }

    setLoadingAPI(value: boolean) {
        this.isLoadingAPI = value;
    }

    showMessage({ successMsg, errorMsg }: IMessage) {
        if (this.orders.error) {
            message.error(errorMsg);
        } else {
            message.success(successMsg)
        }
    }

    // Load orders from api
    async loadOrders(pageNumber: number = this.defaultPageNumber, pageSize: number = this.defaultPageSize) {
        try {
            let queryParameters = "";
            if(this.query){
                queryParameters += `search=${this.query}&`;
            }        
            let [startDate, endDate] = this.dateRange!;
            queryParameters += `startDate=${startDate?.format('YYYY-MM-DD')}&endDate=${endDate?.format('YYYY-MM-DD')}&`;
            if (pageNumber && pageSize) {
                queryParameters += `pageNumber=${pageNumber}&pageSize=${pageSize}`;
            }
            await this.orders.fetch(queryParameters);
        }
        catch (e) {
            console.log('Error fetching clients', e);
        }
    }

    setParcelLabels(labels: IParcelLabel[]) {
        this.currentLabels = labels;
    }

    // get labels from api
    async loadLabels() {
        this.setLoadingAPI(true);
        try {
            this.setParcelLabels([])
            const reponse = await APIService.Order.getLabels(this.currentOrder!.refNo);
            this.setParcelLabels(reponse.data)
        } catch (error) {
            message.error("Could not get labels.")
        }
        this.setLoadingAPI(false);
    }

    getOrderInfo(sku: string) {
        return this.currentOrder!.orderInfo.find(x => x.sku === sku);
    }

    // get orders inventory list
    async fecthInventoryList() {
        this.setLoadingAPI(true);
        let inventoryList: ResponseFormat<IInventoryMaster> = { data: [] };
        try {
            const skuList = this.currentOrder!.orderInfo.reduce((prev, curr) => prev + curr.sku + ",", "");
            inventoryList = await APIService.Inventory.getInventoryMasters(`skuList=${skuList}`)
            this.inventoryList = inventoryList.data.map(item => copyObjects(item, {
                orderInfo: this.getOrderInfo(item.sku)
            }) as ExtendedInventoryList);
        } catch (error) {
            this.inventoryList = []
            message.error('Could not get inventory list.');
        }
        this.setLoadingAPI(false);
    }

    async onUnreadMessage(orderId: string) {
        await this.loadOrders();
        let order = this.orders.value.data.find(x => x.id === orderId);
        if (!order) {
            try {
                order = await APIService.Order.getOrder(orderId)
            } catch (error) {
                message.error('Could not load message.');
                return;
            }
        }
        this.editOrder(order, "3");
    }

    async onFormSubmit(order: IOrder, parcels?: ParcelItem[]) {
        let updatedBody = copyObjects(this.currentOrder, order);

        if (this.currentOrder) {
            if (parcels) {
                this.setLoadingAPI(true);
                try {
                    this.orders.error = null
                    await APIService.Order.createParcel(updatedBody.id, parcels);
                } catch (error) {
                    this.orders.error = new Error(`${error}`);
                }
                this.setLoadingAPI(false);
                this.showMessage({
                    successMsg: 'Created parcels',
                    errorMsg: 'Could not created parcels.',
                });
            } else {
                await this.orders.update(updatedBody);
                this.showMessage({
                    successMsg: 'Order status updated.',
                    errorMsg: 'Could not update order status.',
                });
            }
        }
    }

    async exportOrdersCSV() {
        const hideLoading = message.loading("Downloading CSV...");
        try {
            const csv = await getOrdersCSV() || "";
            const csvBlob = new Blob([csv], { type: "text/csv" });
            
            const reader = new FileReader();
            reader.readAsDataURL(csvBlob);
            reader.onloadend = (e) => {
                downloadObject(reader.result as string, "Orders.csv");
            };
            reader.onerror = (error) => {
                message.error("Error downloading file");
            };
            ;
        } catch (error) {
            // Handle errors and optionally show a message
            message.error("Error downloading CSV");
            return null;
        }
        hideLoading();
    }

    async retryOrderProcessing(orderId: string) {
        this.setLoadingAPI(true);
        try {
            const response = await APIService.Order.retryOrderProcessing(orderId);
            message.success("Successfully retried order processing.");
            await this.loadOrders();
        } catch (error) {
            message.error("Error retrying order processing.");
        } finally {
            this.setLoadingAPI(false);
        }
    }

    async getOrder(orderId: string) {
        try{
            this.currentOrder = await APIService.Order.getOrder(orderId);
            return this.currentOrder;
        }
        catch (error){
            message.error("Could not get order.");
            return null;
        }
    }

}