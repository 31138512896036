import { observable, action, computed, makeObservable } from "mobx";
import { LoadManager } from "../utils/LoadManager/LoadManager";
import { message } from "antd";
import { IEmailNotification } from "../utils/interfaces";
import { getEmails, addEmail, deleteEmail, updateEmail } from "../services/api-service/email-notification";
import { EmailType } from "../utils/enums";

export class EmailNotificationStore {
    emailNotifications = new LoadManager<IEmailNotification>({ data: [] }, getEmails, addEmail, deleteEmail, updateEmail);
    currentEmail?: IEmailNotification | null;
    showPanel = false;
    selectedType?: EmailType;

    constructor() {
        makeObservable(this, {
            emailNotifications: observable,
            currentEmail: observable,
            showPanel: observable,
            selectedType: observable,
            editEmail: action,
            addEmail: action,
            tooglePanel: action,
            isLoading: computed,
        });
    }

    get isNew(): boolean {
        return !this.currentEmail;
    }

    tooglePanel(v: boolean, type?: EmailType) {
        this.showPanel = v;
    }

    editEmail(email?: IEmailNotification) {
        this.currentEmail = email;
    }

    addEmail(): void {
        this.currentEmail = null;
    }

    get isLoading(): boolean {
        return this.emailNotifications.isLoading;
    }

    showMessage({ successMsg, errorMsg }: { successMsg: string; errorMsg: string }) {
        if (this.emailNotifications.error) {
            message.error(errorMsg);
        } else {
            message.success(successMsg);
        }
    }

    async loadEmails(type?: EmailType) {
        await this.emailNotifications.fetch(type);
    }

    async onFormSubmit(email: IEmailNotification) {
        try {
            if (this.currentEmail) {
                await this.emailNotifications.update(email);
                this.showMessage({
                    successMsg: 'Email updated successfully.',
                    errorMsg: 'Could not update email.',
                });
            } else {
                await this.emailNotifications.add(email);
                this.showMessage({
                    successMsg: 'Email added successfully.',
                    errorMsg: 'Could not add email.',
                });
            }
            this.editEmail(undefined);
            setTimeout(() => this.showPanel = false, 3000);
        } catch (e) {
            console.log('Error updating/adding email', e);
        }
    }

    async deleteEmail(email: IEmailNotification) {
        try {
            await this.emailNotifications.remove(email);
            this.showMessage({
                successMsg: 'Email deleted successfully.',
                errorMsg: 'Could not delete email.',
            });
        } catch (e) {
            console.log('Error deleting email', e);
        }
    }


        resetForm() {
            this.showPanel = false;
            this.selectedType = undefined;
        }
    }

export default new EmailNotificationStore();
