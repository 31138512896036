import { QuestionCircleOutlined } from "@ant-design/icons";
import { Popconfirm, Space } from "antd";
import React from "react";
import { Buttons } from "..";

const PopupConfirm = ({
    onConfirm,
    isLoading,
    btnTitle = 'Deactivate',
    ...rest
}: any) => {

    const handleConfirm = () => {
        onConfirm && onConfirm();
    };

    return (
        <Space>
            <Popconfirm
                title="Continue and delete this item?"
                placement='leftBottom'
                okButtonProps={{ className: 'text-btn', type: 'text' }}
                cancelButtonProps={{ className: 'text-btn', type: 'text' }}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                onConfirm={handleConfirm}
                {...rest}
            >
                <Buttons.Text
                    text={btnTitle}
                    loading={isLoading}
                />
            </Popconfirm>
        </Space >
    );
};

export default PopupConfirm;