import React from 'react';
import { Modal, Button, Input, message, List } from 'antd';
import {  Document, Text, MultipleChoice,MultiSelect, Email, ISurveyQuestion } from "../../utils/interfaces";
// import './style.less'
import { AxiosError } from 'axios';
import { useAuthContext } from '../../context/AuthContext';
import { MdAdd } from 'react-icons/md';
import { observer } from 'mobx-react-lite';
import { TypeId } from "../../utils/enums";
import { Store } from '../../stores/stores';

type ModalProps = {
    visible: boolean,
    setVisible: Function,
    question: ISurveyQuestion | undefined,
}

const QuestionModal = observer(({ visible, setVisible, question, }: ModalProps) => {
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [questionContent, setQuestionContent] = React.useState<ISurveyQuestion>();
    const [questionTitle, setQuestionTitle] = React.useState<string>('');
    const [optTitle, setOptTitle] = React.useState<string>('');
    const [questionItems, setQuestionItems] = React.useState<string[]>([]);
    const [questionType, setQuestionType] = React.useState<string>();
    const [questionView, setQuestionView] = React.useState(<> </>);

    const { authKey } = useAuthContext();
     const {surveyQuestion} = Store;
    const putQuestion = () => {
        setConfirmLoading(true);

        // console.log(JSON.stringify(question.questionContent))
        if(!question){
            message.error("Could not set question content")
            return
        }
        if(questionContent){
            questionContent.options = questionItems;
            questionContent.text = questionTitle;
        }
        else{
            message.error("Could not set question content")
            return
        }
        // console.log(question)

        if (questionTitle.length < 1) {
            message.warning("question title")
        }
        else {
            try {
                surveyQuestion.updateSurveyQuestion(question.id,questionContent as ISurveyQuestion)
                 setVisible(false);
                 setConfirmLoading(false);
                 message.success("Question updated successfully")
            } catch (error) {
                console.error(error);
            }
            
        }
    }

    const addQuestionItems = () => {
        if (optTitle.length > 0) {
            setQuestionItems(items => [...items, optTitle])
            setOptTitle('')
        }
    }

    const deleteQuestionItems = (qItem:any) => {
        setQuestionItems(questionItems.filter(item => item !== qItem));
        setOptTitle('')
    }

    React.useEffect(() => {
        if (question !== undefined) {
            // console.log(questionItems)
            if (question.questionTypeId === TypeId.MultipleChoice) {
                setQuestionView(generateMultipleChoiceQuestion({items:questionContent?.options ?? [], title:questionContent?.text ?? ''}))
            }
            else if (question.questionTypeId === TypeId.MultiSelect) {
                setQuestionView(generateMultiSelectQuestion({items:questionContent?.options ?? [], title:questionContent?.text ?? ''}))
            }
        }

    }, [questionItems])

    React.useEffect(() => {
        if (question !== undefined) {
            if (question.questionTypeId === TypeId.MultipleChoice) {
                setQuestionView(generateMultipleChoiceQuestion({items:questionContent?.options ?? [], title:questionContent?.text ?? ''}))
            }
            else if (question.questionTypeId === TypeId.MultiSelect) {
                setQuestionView(generateMultiSelectQuestion({items:questionContent?.options ?? [], title:questionContent?.text ?? ''}))
            }
        }

    }, [optTitle])

    React.useEffect(() => {
        if (question !== undefined) {
            if (question.questionTypeId === TypeId.Text ) {
                setQuestionView(generateTextQuestion({title:questionContent?.text ?? ''}))
            }
            if (question.questionTypeId === TypeId.MultipleChoice) {
                setQuestionView(generateMultipleChoiceQuestion({items:questionContent?.options ?? [], title:questionContent?.text ?? ''}))
            }
            if (question.questionTypeId === TypeId.MultiSelect) {
                setQuestionView(generateMultiSelectQuestion({items:questionContent?.options ?? [], title:questionContent?.text ?? ''}))
            }
            else if (question.questionTypeId === TypeId.Email) {
                setQuestionView(generateEmailQuestion({title:questionContent?.text ?? ''}))
            }
            else if (question.questionTypeId === TypeId.Document) {
                setQuestionView(generateDocumentQuestion({title:questionContent?.text ?? ''}))
            }
              else if (question.questionTypeId === TypeId.Number) {
                setQuestionView(generateNumberQuestion({title:questionContent?.text ?? ''}))
            }
        }

    }, [questionTitle])

    React.useEffect(() => {
        if (question && visible) {
            console.log("Test")
            setQuestionType(question.questionTypeId);
            setQuestionContent(question);
            setQuestionTitle(question.text || '');
            setOptTitle('');
            if (question.questionTypeId === TypeId.Text) {
                let questContent: ISurveyQuestion = question;
                setQuestionContent(questContent)
                setQuestionTitle(questContent?.text ?? '')
                setQuestionView(generateTextQuestion({title:questionContent?.text ?? ''}))
            }
            else if (question.questionTypeId === TypeId.MultipleChoice) {
                let questContent: ISurveyQuestion = question;
                setQuestionContent(questContent)
                setQuestionTitle(questContent?.text ?? '')
                setQuestionItems(questContent.options ? [...questContent.options] : []);
                setQuestionView(generateMultipleChoiceQuestion({items:questionContent?.options ?? [], title:questionContent?.text ?? ''}))
            }
            else if (question.questionTypeId === TypeId.MultiSelect) {
                let questContent: ISurveyQuestion = question;
                // console.log(questContent)
                setQuestionContent(questContent)
                setQuestionTitle(questContent?.text ?? '')
                setQuestionItems(questContent.options ? [...questContent.options] : []);
                setQuestionView(generateMultiSelectQuestion({items:questionContent?.options ?? [], title:questionContent?.text ?? ''}))
            }
            else if (question.questionTypeId === TypeId.Email) {
                let questContent: ISurveyQuestion = question;
                setQuestionContent(questContent)
                setQuestionTitle(questContent?.text ?? '')
                setQuestionView(generateEmailQuestion({title:questionContent?.text ?? ''}))
            }
            else if (question.questionTypeId === TypeId.Document) {
                let questContent: ISurveyQuestion = question;
                setQuestionContent(questContent)
                setQuestionTitle(questContent?.text ?? '')
                setQuestionView(generateDocumentQuestion({title:questionContent?.text ?? ''}))
            }
             else if (question.questionTypeId === TypeId.Number) {
                let questContent: ISurveyQuestion = question;
                setQuestionContent(questContent)
                setQuestionTitle(questContent?.text ?? '')
                setQuestionView(generateNumberQuestion({title:questionContent?.text ?? ''}))
            }
        }

    }, [question, visible])

    const generateTextQuestion = (q: Text) => (
        <>
            <div>Title:</div> <Input value={questionTitle} className='modal-data-input' onChange={(val) => setQuestionTitle(val.target.value)}></Input>
        </>
    )
    const generateNumberQuestion = (q: Text) => (
        <>
            <div>Title:</div> <Input value={questionTitle} className='modal-data-input' onChange={(val) => setQuestionTitle(val.target.value)}></Input>
        </>
    )
    const generateEmailQuestion = (q: Email) => (
        <>
            <div>Title:</div> <Input value={questionTitle} className='modal-data-input' onChange={(val) => setQuestionTitle(val.target.value)}></Input>
        </>
    )
     const generateDocumentQuestion = (q: Document) => (
        <>
            <div>Title:</div> <Input value={questionTitle} className='modal-data-input' onChange={(val) => setQuestionTitle(val.target.value)}></Input>
        </>
    )


    const generateMultipleChoiceQuestion = (q: MultipleChoice) => (
        <>
            <div>Title:</div> <Input value={questionTitle} className='modal-data-input' onChange={(val) => setQuestionTitle(val.target.value)}></Input>
            <div>
                <div className="items-head">
                    <span className='new-opt'> New Options</span>
                    <Input placeholder={"Add new items"} value={optTitle} onChange={(val) => setOptTitle(val.target.value)} style={{ width: "70%" }} className='modal-data-input'></Input>
                    <Button onClick={() => addQuestionItems()} className='add-item-btn' icon={<MdAdd style={{ fontSize: '1.7rem' }} />} />
                </div>
                <List size="small"
                    header={<div>Question options</div>}
                    bordered
                    dataSource={questionItems}
                    renderItem={item =>
                        <List.Item className='quest-list-item' >
                            {item}
                            <Button onClick={() => deleteQuestionItems(item)} className='delete-q'>Delete</Button>
                        </List.Item>
                    } ></List>
            </div>
        </>
    )
    const generateMultiSelectQuestion = (q: MultiSelect) => (
        <>
            <div>Title:</div> <Input value={questionTitle} className='modal-data-input' onChange={(val) => setQuestionTitle(val.target.value)}></Input>
            <div>
                <div className="items-head">
                    <span className='new-opt'> New Options</span>
                    <Input placeholder={"Add new items"} value={optTitle} onChange={(val) => setOptTitle(val.target.value)} style={{ width: "70%" }} className='modal-data-input'></Input>
                    <Button onClick={() => addQuestionItems()} className='add-item-btn' icon={<MdAdd style={{ fontSize: '1.7rem' }} />} />
                </div>
                <List size="small"
                    header={<div>Question options</div>}
                    bordered
                    dataSource={questionItems}
                    renderItem={item =>
                        <List.Item className='quest-list-item' >
                            {item}
                            <Button onClick={() => deleteQuestionItems(item)} className='delete-q'>Delete</Button>
                        </List.Item>
                    } ></List>
            </div>
        </>
    )

    const handleOk = () => {
        putQuestion()
    };

    const handleCancel = () => {
        setVisible(false);
        setConfirmLoading(false)
    };



    return (
        <>
            <Modal
                width={"40%"}
                title="Edit Question"
                visible={visible}
                className="data-input-modal"
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={"Question updated"}
                footer={[
                    <Button key="back" className="cancel-btn" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" className="reg-btn" type="primary" loading={confirmLoading} onClick={handleOk}>
                        Save
                    </Button>,
                ]}
            >
                <div>
                    {questionView}
                </div>
            </Modal>
        </>
    )
});

export default QuestionModal;