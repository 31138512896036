import { Layouts } from "../utils/enums";
import { Landing } from "./landing";
import LinkAccountNumber from "./landing/link-account-number";
import AccountVerification from "./landing/account-verification";
import ForgotPassword from "./landing/forgot-password";

export const pages = [
    {
        path: '/',
        exact: true,
        component: () => <Landing />,
        layout: Layouts.Landing,
    },
    {
        path: '/account-verification/:token',
        exact: true,
        component: () => <AccountVerification />,
        layout: Layouts.Landing,
    },
    {
        path: '/account-verification/:token/subAccounts',
        exact: true,
        component: () => <LinkAccountNumber />,
        layout: Layouts.Landing,
    },
    {
        path: '/forgot-password',
        exact: true,
        component: () => <ForgotPassword />,
        layout: Layouts.Landing,
    },
];